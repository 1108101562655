import React ,{useEffect,useState}from 'react';
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './Contact/Content'
import { listHomecontacts } from '../service/apiService';

export const Contact = () => {
  const [homeContact,sethomeContact]=useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    getHomeContact();
  },[]);
  const getHomeContact=async()=>{
    const homeContact=await listHomecontacts();
    console.log(homeContact.data.data);
    sethomeContact(homeContact.data.data);
  }

  return (
   <>
    <BreadCumb bgImage={"url('assets/img/banner/contact.jpeg')"} routeLink={"contact"} mainRoute={"Home"} 
    route={"Contact"} title={"Contact"}/>
   <Content address={homeContact}/>
   </>
  )
}
